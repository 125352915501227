<template>
  <div class="user-create"><v-user-management /></div>
</template>

<script>
import VUserManagement from "../../components/users/VUserManagement";

export default {
  name: "UserCreate",

  components: { VUserManagement },

  metaInfo() {
    return { title: this.$t("Users.CreateUser") };
  },
};
</script>

<style lang="scss" scoped>
</style>